<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách doanh nghiêp hợp lệ</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :maxHeight="'100%'"
          :columns="columns"
          :rows="dataInvalid"
          :sort-options="{
            enabled: false,

          }"

          max-height="100%"
          style-class="vgt-table"
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
        >
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p v-if="dataInvalid">
          Tổng cộng: {{ dataInvalid.length }} doanh nghiêp
        </p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách doanh nghiêp không hợp lệ</h4>
        </div>
        <div class="listwork__head__02__right">

          <b-button
            variant="primary"
            class="btn-icon"
            @click="onOffEdit()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
          style-class="vgt-table"
          :sort-options="{
            enabled: false,

          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- code- -->
            <div
              v-if="props.column.field == 'code' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Code'||!props.row.code,dataErr:!props.row.code,}"
            >

              {{ props.row.code }}
            </div>

            <div
              v-if="props.column.field == 'code' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Code'"
                class="style-table"
              >{{ props.row.code }}</div>
            </div>
            <!-- name- -->
            <div
              v-if="props.column.field == 'name' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >

              {{ props.row.name }}
            </div>

            <div
              v-if="props.column.field == 'name' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Name'"
                class="style-table"
              >{{ props.row.name }}</div>
            </div>
            <!-- userName-- -->
            <div
              v-if="props.column.field == 'userName' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'UserName'||!props.row.userName,dataErr:!props.row.userName,}"
            >

              {{ props.row.userName }}
            </div>

            <div
              v-if="props.column.field == 'userName' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'UserName'"
                v-model="props.row.userName"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.userName, 'userName', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'UserName'"
              >{{ props.row.userName }}</div>
            </div>
            <!-- password-- -->
            <div
              v-if="props.column.field == 'password' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Password'||!props.row.name,dataErr:!props.row.password,}"
            >

              {{ props.row.password }}
            </div>

            <div
              v-if="props.column.field == 'password' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Password'"
                v-model="props.row.password"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.password, 'password', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Password'"
                class="style-table"
              >{{ props.row.password }}</div>
            </div>
            <!-- email -->
            <div
              v-if="props.column.field == 'email' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Email'||!props.row.email,dataErr:!props.row.email,}"
            >

              {{ props.row.email }}
            </div>

            <div
              v-if="props.column.field == 'email' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Email'"
                v-model="props.row.email"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.email, 'email', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Email'"
                class="style-table"
              >{{ props.row.email }}</div>
            </div>
            <!-- decisionEstablish-Ngày thành lập -->
            <div
              v-if="props.column.field == 'decisionEstablish' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'DecisionEstablish'||!props.row.decisionEstablish,dataErr:!props.row.decisionEstablish,}"
            >

              {{ props.row.decisionEstablish }}
            </div>

            <div
              v-if="props.column.field == 'decisionEstablish' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'DecisionEstablish'"
                v-model="props.row.decisionEstablish"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.decisionEstablish, 'decisionEstablish', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'DecisionEstablish'"
                class="style-table"
              >{{ props.row.decisionEstablish }}</div>
            </div>
            <!-- Đơn vị cấp : unitIssued -->
            <div
              v-if="props.column.field == 'unitIssued' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'UnitIssued'||!props.row.unitIssued,dataErr:!props.row.unitIssued,}"
            >

              {{ props.row.unitIssued }}
            </div>

            <div
              v-if="props.column.field == 'unitIssued' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'UnitIssued'"
                v-model="props.row.unitIssued"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.unitIssued, 'unitIssued', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'UnitIssued'"
                class="style-table"
              >{{ props.row.unitIssued }}</div>
            </div>
            <!-- Ngày cấp : dateIssued -->
            <div
              v-if="props.column.field == 'dateIssued' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'DateIssued'||!props.row.dateIssued,dataErr:!props.row.dateIssued,}"
            >

              {{ props.row.dateIssued }}
            </div>

            <div
              v-if="props.column.field == 'dateIssued' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'DateIssued'"
                v-model="props.row.dateIssued"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.dateIssued, 'dateIssued', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'DateIssued'"
                class="style-table"
              >{{ props.row.dateIssued }}</div>
            </div>
            <!-- Nơi cấp : locationIssued -->
            <div
              v-if="props.column.field == 'locationIssued' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'LocationIssued'||!props.row.locationIssued,dataErr:!props.row.locationIssued,}"
            >

              {{ props.row.locationIssued }}
            </div>

            <div
              v-if="props.column.field == 'locationIssued' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'LocationIssued'"
                v-model="props.row.locationIssued"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.locationIssued, 'locationIssued', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'LocationIssued'"
                class="style-table"
              >{{ props.row.locationIssued }}</div>
            </div>
            <!-- Ngày hết hạn : dateExpired -->
            <div
              v-if="props.column.field == 'dateExpired' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'DateExpired'||!props.row.dateExpired,dataErr:!props.row.dateExpired,}"
            >

              {{ props.row.dateExpired }}
            </div>

            <div
              v-if="props.column.field == 'dateExpired' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'DateExpired'"
                v-model="props.row.dateExpired"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.dateExpired, 'dateExpired', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'DateExpired'"
                class="style-table"
              >{{ props.row.dateExpired }}</div>
            </div>
            <!-- Mã số thuế : taxNumber -->
            <div
              v-if="props.column.field == 'taxNumber' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'TaxNumber'||!props.row.taxNumber,dataErr:!props.row.taxNumber,}"
            >

              {{ props.row.taxNumber }}
            </div>

            <div
              v-if="props.column.field == 'taxNumber' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'TaxNumber'"
                v-model="props.row.taxNumber"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.taxNumber, 'taxNumber', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'TaxNumber'"
                class="style-table"
              >{{ props.row.taxNumber }}</div>
            </div>
            <!-- Địa chỉ : address -->
            <div
              v-if="props.column.field == 'address' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Address'||!props.row.address,dataErr:!props.row.address,}"
            >

              {{ props.row.address }}
            </div>

            <div
              v-if="props.column.field == 'address' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Address'"
                v-model="props.row.address"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.address, 'address', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Address'"
                class="style-table"
              >{{ props.row.address }}</div>
            </div>
            <!-- Số điện thoại : phoneNumber -->
            <div
              v-if="props.column.field == 'phoneNumber' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'PhoneNumber'||!props.row.phoneNumber,dataErr:!props.row.phoneNumber,}"
            >

              {{ props.row.phoneNumber }}
            </div>

            <div
              v-if="props.column.field == 'phoneNumber' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'PhoneNumber'"
                v-model="props.row.phoneNumber"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.address, 'phoneNumber', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'PhoneNumber'"
              >{{ props.row.phoneNumber }}</div>
            </div>
            <!-- -----------end -->
            <!-- Loai hình doanh nghiêp : bussinessType -->
            <div
              v-if="props.column.field == 'bussinessType' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'BussinessType'||!props.row.bussinessType,dataErr:!props.row.bussinessType,}"
            >

              {{ props.row.bussinessType }}
            </div>
            <div
              v-if="props.column.field == 'bussinessType' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'BussinessType'"
                v-model="props.row.bussinessType"
                :reduce="data => data.name"
                :label="'name'"
                :placeholder="`Lựa chọn loại doanh nghiệp `"
                :options="selectbussinessType"
                @input="selectClassify($event,props.row.bussinessType, 'bussinessType', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'BussinessType'"
                class="style-table"
              >{{ props.row.bussinessType }}</div>
            </div>
            <!-- // phòng trực thuộc -->
            <div
              v-if="props.column.field == 'department' && editvalueErr === false"
              class="style-table"
            >

              {{ props.row.department }}
            </div>

            <!-- Ngành nghề kinh doanh chính : bussinessSector -->
            <div
              v-if="props.column.field == 'bussinessSector' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'BussinessSector'||!props.row.bussinessSector,dataErr:!props.row.bussinessSector,}"
            >

              {{ props.row.bussinessSector }}
            </div>
            <div
              v-if="props.column.field == 'bussinessSector' && editvalueErr === true "
              class="style-table"
            >
              <business-sector-select
                v-if="props.row.messErr[0].location === 'BussinessSector'"
                v-model="props.row.parentIdName"
                @input="changeCellvalueOr(props.row.bussinessSector, 'bussinessSector', props.row)"
                @checkNameText="checkNameText"
              />
              <div
                v-if="props.row.messErr[0].location !== 'BussinessSector'"
                class="style-table"
              >{{ props.row.bussinessSector }}</div>
            </div>
            <!-- Nguườii đại diện : officalProfile -->
            <div
              v-if="props.column.field == 'officalProfile' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'OfficalProfile'||!props.row.officalProfile,dataErr:!props.row.officalProfile,}"
            >

              {{ props.row.officalProfile }}
            </div>
            <div
              v-if="props.column.field == 'officalProfile' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'OfficalProfile'"
                v-model="props.row.officalProfile"
                :reduce="title => `${title.code}-${title.name}`"
                label="name"
                :options="ArraySelectUser"
                :placeholder="'Lựa chọn người đại diện'"
                @input="changeCellvalue(props.row.officalProfile, 'officalProfile', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'OfficalProfile'"
                class="style-table"
              >{{ props.row.officalProfile }}</div>
            </div>

            <!-- Loại doanh nghiệp : bussinessClass -->
            <div
              v-if="props.column.field == 'bussinessClass' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'BussinessClass'||!props.row.bussinessClass,dataErr:!props.row.bussinessClass,}"
            >

              {{ props.row.bussinessClass }}
            </div>
            <div
              v-if="props.column.field == 'bussinessClass' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'BussinessClass'"
                v-model="props.row.bussinessClass"
                :reduce="data => data.name"
                :label="'name'"
                :placeholder="`Lựa chọn loại doanh nghiệp `"
                :options="selectbussinessClass"
                @input="selectClassify($event,props.row.bussinessClass, 'bussinessClass', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'BussinessClass'"
                class="style-table"
              >{{ props.row.bussinessClass }}</div>
            </div>

            <!-- Kiểu người dùng : userType -->
            <div
              v-if="props.column.field == 'userType' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'UserType'||!props.row.userType,dataErr:!props.row.userType,}"
            >

              {{ props.row.userType }}
            </div>
            <div
              v-if="props.column.field == 'userType' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'UserType'"
                v-model="props.row.userType"
                :reduce="data => data.name"
                :label="'name'"
                :placeholder="`Lựa chọn kiểu người dùng`"
                :options="dataCombobox"
                @input="selectClassify($event,props.row.userType, 'userType', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'UserType'"
                class="style-table"
              >{{ props.row.userType }}</div>
            </div>
            <!-- ------------------------ô select------- -->
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p v-if="dataNoInvalid">
          Tổng cộng: {{ dataNoInvalid.length }} doanh nghiêp
        </p>
      </div>

      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addBusiness()"
          >
            Thêm doanh nghiêp
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import ConstantsApi from '../../constants/ConstantsApi'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import BusinessSectorSelect from '@/views/management-business/business-sector/pages/components/BusinessSectorSelect.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    VSelect,
    VBTooltip,
    BusinessSectorSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      valueSelect123: '',
      selectbussinessClass: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'Accreditation',
          name: 'Tổ chức kiểm định',
        },
        {
          id: 'Training',
          name: 'Tổ chức huấn luyện',
        },
        {
          id: 'Health',
          name: 'Trung tâm y tế',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
      ], //  Loại doanh nghiệp
      selectbussinessClassify: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'CoOperative',
          name: 'Hợp tác xã',
        },
        {
          id: 'StateAgencies',
          name: 'Cơ quan, tổ chức',
        },
      ], // data lựa chọn phân loại doanh nghiệp
      valueSelectone: '',
      valueSelect: [
        { name: 'Khác' },
        {
          name: 'Nhà quản lý',
        },
        {
          name: 'Chuyên môn kỹ thuật bậc cao',
        },
        {
          name: 'Chuyên môn kỹ thuật bậc trung',
        },
      ],
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã doanh nghiệp',
          field: 'code',
          width: '200px',
        },
        {
          label: 'Tên doanh nghiệp',
          field: 'name',
          width: '200px',
        },
        {
          label: 'Tên đăng nhập',
          field: 'userName',
          width: '200px',
        },
        {
          label: 'Mật khẩu',
          field: 'password',
          width: '200px',
        },
        {
          label: 'Email',
          field: 'email',
          width: '200px',
        },
        {
          label: 'Số quyết định thành lập',
          field: 'decisionEstablish',
          width: '200px',
        },
        {
          label: 'Đơn vị cấp',
          field: 'unitIssued',
          width: '200px',
        },
        {
          label: 'Ngày cấp',
          field: 'dateIssued',
          width: '200px',
        },
        {
          label: 'Nơi cấp',
          field: 'locationIssued',
          width: '200px',
        },
        {
          label: 'Ngày hết hạn',
          field: 'dateExpired',
          width: '200px',
        },
        {
          label: 'Mã số thuế',
          field: 'taxNumber',
          width: '200px',
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          width: '200px',
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          width: '200px',
        },
        {
          label: 'Loại hình doanh nghiệp',
          field: 'bussinessType',
          width: '200px',
        },
        {
          label: 'Ngành nghề kinh doanh chính',
          field: 'bussinessSector',
          width: '200px',
        },
        {
          label: 'Người đại diện',
          field: 'officalProfile',
          width: '200px',
        },
        {
          label: 'Loại doanh nghiệp',
          field: 'bussinessClass',
          width: '200px',
        },
        {
          label: 'Kiểu người dùng',
          field: 'userType',
          width: '200px',
        },
        {
          label: 'Phòng trực thuộc',
          field: 'department',
          width: '200px',
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
          tdClass: 'style-tablue',
          thClass: 'style-tablue',
        },
        {
          label: 'Mã doanh nghiệp',
          field: 'code',
          tdClass: 'style-tablue',
          thClass: 'style-tablue',
        },
        {
          label: 'Tên doanh nghiệp',
          field: 'name',
        },
        {
          label: 'Tên đăng nhập',
          field: 'userName',
        },
        {
          label: 'Mật khẩu',
          field: 'password',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Số quyết định thành lập',
          field: 'decisionEstablish',
        },
        {
          label: 'Đơn vị cấp',
          field: 'unitIssued',
        },
        {
          label: 'Ngày cấp',
          field: 'dateIssued',
        },
        {
          label: 'Nơi cấp',
          field: 'locationIssued',
        },
        {
          label: 'Ngày hết hạn',
          field: 'dateExpired',
        },
        {
          label: 'Mã số thuế',
          field: 'taxNumber',
        },
        {
          label: 'Địa chỉ',
          field: 'address',
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
        },
        {
          label: 'Loại hình doanh nghiệp',
          field: 'bussinessType',
        },
        {
          label: 'Ngành nghề kinh doanh chính',
          field: 'bussinessSector',
        },
        {
          label: 'Người đại diện',
          field: 'officalProfile',
        },
        {
          label: 'Loại doanh nghiệp',
          field: 'bussinessClass',
        },
        {
          label: 'Kiểu người dùng',
          field: 'userType',
        },
        {
          label: 'Phòng trực thuộc',
          field: 'department',
          width: '200px',
        },
      ],
      dataCheck: [],
      selectbussinessType: '',
      ArraySelectUser: '',
      businessName: null,
      listDepartment: [],
    }
  },
  computed: {
    ...mapState('business', ['dataVaild', 'dataNoValid']),
    ...mapGetters('userType', ['dataCombobox']),
  },

  mounted() {
    this.getComboboxDepartment()
    // tdClass: 'style-code123',
    this.doFetchUserTypeCombobox()
    this.dataInvalid = this.dataVaild
    this.dataNoInvalid = this.dataNoValid
    this.$hideAllPageLoading()
  },
  async created() {
    const dataBusinessType = await this.fecthDataBusinessType()
    const data = await this.fecthDataOfficalProfileCombobox()
    this.ArraySelectUser = data.data
    this.selectbussinessType = dataBusinessType.data.data
  },
  methods: {
    ...mapActions('business', ['getApiExcel', 'fecthDataBusinessType', 'fecthDataOfficalProfileCombobox']),
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),
    async getComboboxDepartment() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_DEPARTMENT)
      this.listDepartment = data?.data
    },
    checkNameText(value) {
      this.businessName = value.name
    },

    valueSelect01234(e) {
      this.valueSelect123 = e
    },
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },

    async changeCellvalueOr(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = this.businessName
    },
    // nút check
    async CheckdataNoInvalid() {
      this.model = {
        listExcel: this.dataInvalid.concat(this.dataNoInvalid),
        isValidate: true,
      }
      this.$showAllPageLoading()
      const valuedata = await this.getApiExcel(this.model)
      this.dataInvalid = valuedata.data.filter(x => x.isSuccess === true)
      const dataTemp = valuedata.data.filter(x => x.isSuccess === false)
      if (dataTemp.length > 0) {
        this.dataNoInvalid = dataTemp
      } else {
        this.dataNoInvalid = []
      }
      this.$hideAllPageLoading()
    },
    // nút hủy bỏ
    close() {
      this.$router.push({ name: 'business' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addBusiness() {
      if (this.dataInvalid && this.dataInvalid.length !== 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        this.$showAllPageLoading()
        const res = await this.getApiExcel(model)
        if (res.oke) {
          this.dataInvalid = []
          if (this.dataNoInvalid && this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'business' })
          }
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thất bại!', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } else {
        this.$root.$bvToast.toast('Không có dữ liệu để thêm!!!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    classErr() {

    },
    selectClassify(e, b, c, d) {
      b = e
      this.changeCellvalue(b, c, d)
    },

  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
.style-table{
  width: 200px;
}
</style>
